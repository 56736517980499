<script lang="ts">
    import { onMount, onDestroy, tick } from 'svelte';
    import axios from 'axios';
    import {
        Icon,
        PencilSquare,
        LockClosed,
        LockOpen,
        PaperAirplane,
        Document,
        Trash,
        Banknotes,
        XMark,
    } from 'svelte-hero-icons';
    import SendingAnimation from '@/Components/messages/SendingAnimation.svelte';
    import { Button } from '$lib/components/ui/button';
    import { Skeleton } from '$lib/components/ui/skeleton';
    import ConversationHeader from '@/Components/messages/ConversationHeader.svelte';
    import ConversationHeaderBox from '@/Components/messages/ConversationHeaderBox.svelte';
    import ConversationBox from '@/Components/messages/ConversationBox.svelte';
    import NewConversationHeader from '@/Components/messages/NewConversationHeader.svelte';
    import Pusher from 'pusher-js';
    import { Picker } from 'emoji-mart';
    import { Textarea } from '$lib/components/ui/textarea';
    import * as Dialog from '$lib/components/ui/dialog';
    import { Input } from '$lib/components/ui/input';
    import { LoaderCircle } from 'lucide-svelte';
    import { v4 as uuidv4 } from 'uuid';
    import { decryptBase64 } from '@/utility/crypt/decrypt.js';
    import './Style.css';
    import toast from 'svelte-french-toast';
    import Dropzone from 'dropzone';
    import PointLight from '@/Icons/PointLight.svelte';
    import MessageAction from './MessageAction.svelte';

    type Contact = {
        last_message_sender_id: number;
        last_message: string;
        isSeen: number;
        created_at: string;
        message_date: string;
        sender_id: number;
        sender_name: string;
        sender_avatar: string;
        sender_role: number;
        receiver_id: number;
        receiver_name: string;
        receiver_avatar: string;
        receiver_role: number;
        contact_id: number;
    };
    // let contacts: Contact[] = [];

    export let data: {
        messengerVars: {
            bootFullMessenger: boolean;
            lastContactID: any | false;
            pusherDebug?: boolean;
            // pusherCluster?: string;
        };

        mediaSettings: {
            allowed_file_extensions: string;
            max_file_upload_size: number;
        };

        socketsDriver: string;
        soketiHost?: string;
        soketiPort?: number;
        useTSL?: boolean;
        key: string;
        pusherKey: string;
        user: {
            user_id: number;
        };
        currencySymbol: string;
        creatorCanEarnMoney: boolean;
    };

    let contacts: Contact[] = [];
    let newChat = false;
    let newChatUserId = '';
    let ChatLoader = true;
    let pusher: any;
    let pickerContainer: HTMLElement;
    let showPicker = false;
    let picker: any;
    let newMessage = '';
    let conversation: any[] = [];
    let activeConversationUserID = '';
    let activeConversationUserUserName = '';
    let activeConversationUserAvatar = '';
    let activeConversationUserName = '';
    let activeConversationUserProfileUrl = '';
    let uploadedFiles: any[] = [];
    let dropzoneElement: any;
    let myDropzone: Dropzone | any;
    let isPaidMessage = false;
    let messagePrice = 5;
    let chatContainer: HTMLElement;
    let deleteDialogOpen = false;
    let openSingleAttachment = false;
    let dropzoneLoading = false;
    let messageSending = false;
    let deleteMsgID = '';
    let hoveredMessageId: any = '';
    let unseenMessagesCount: any;
    let mobileShowChat = false;
    let isMobile = false;
    let channelName = '';
    let previewsContainer: HTMLDivElement | any;

    let isPriceUpdateMode = false;
    let priceEditMessageID = '';

    let openPriceModal = false;

    console.log('Data', data);
    let isTyping = false;
    let isReceiverTyping = false;

    const isVideo = ['avi', 'mp4', 'wmv', 'mpeg', 'm4v', 'moov', 'mov'];
    const isAudio = ['mp3', 'wav', 'ogg'];
    const isImage = ['png', 'jpg', 'jpeg', 'avif'];

    $: if (conversation && conversation.length > 0) {
        scrollToBottom();
    }

    $: if (chatContainer) {
        scrollToBottom('instant');
    }

    const fetchContacts = async () => {
        await axios
            .get(`${window.location.origin}/my/messenger/fetchContacts`)
            .then((response) => {
                if (response.data.status === 'success') {
                    contacts = response.data.data.contacts;
                    if (contacts.length === 0) {
                        data.messengerVars.lastContactID = false;
                        if (!newChat) ChatLoader = true;
                    }
                }
            })
            .catch((error) => console.error('Error fetching contacts:', error));
    };

    const scrollToBottom = async (behavior: ScrollBehavior = 'smooth') => {
        if (chatContainer) {
            await tick();
            const scrollHeight = chatContainer.scrollHeight;
            chatContainer.scrollTo({
                top: scrollHeight,
                behavior: behavior,
            });
        }
    };

    onMount(async () => {
        Dropzone.autoDiscover = false;

        if (data.messengerVars.bootFullMessenger) {
            initializePusher();
            await fetchContacts();

            if (data.messengerVars.lastContactID !== false && data.messengerVars.lastContactID !== '0') {
                fetchConversation(data.messengerVars.lastContactID);
            }
        }

        isMobile = window.innerWidth < 767;

        myDropzone = new Dropzone(dropzoneElement, {
            url: '/attachment/upload/message',
            clickable: '.file-upload-button',
            paramName: 'file',
            headers: {
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
            },
            maxFilesize: data.mediaSettings.max_file_upload_size,
            addRemoveLinks: true,
            dictRemoveFile: `<button class="text-bmn-500 mt-3 hover:text-bmn-700">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                         </button>`,
            acceptedFiles: data.mediaSettings.allowed_file_extensions,
            autoProcessQueue: true,
            previewsContainer: previewsContainer,
            parallelUploads: 2,
            init() {
                console.log('Dropzone init() called');

                this.on('addedfile', (file) => {
                    dropzoneLoading = true;
                });

                this.on('success', async (file: any, response: any) => {
                    uploadedFiles.push({
                        attachmentID: response.attachmentID,
                        path: response.path,
                        type: response.type,
                        thumbnail: response.thumbnail,
                    });
                    file.upload.assetSrc = response.assetSrc;
                    dropzoneLoading = false;
                });

                this.on('error', (file: any, errorMessage: any, xhr: any) => {
                    console.error('Dropzone: error ->', file.name, errorMessage, xhr);
                });

                this.on('queuecomplete', () => {
                    console.log('Dropzone all queue completed');

                    dropzoneLoading = false;
                });
            },
        });

        console.log('Contacts', contacts);
    });

    const initializePusher = (): void => {
        let params: any = {
            wsHost: data.soketiHost,
            wsPort: data.soketiPort,
            forceTLS: data.useTSL ? true : false,
            encrypted: true,
            enabledTransports: ['ws', 'wss'],
            auth: {
                headers: {
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content'),
                },
            },
            authorizer: (channel: any) => {
                return {
                    authorize: (socketId: string, callback: Function) => {
                        axios
                            .post('/my/messenger/authorizeUser', {
                                socket_id: socketId,
                                channel_name: channel.name,
                            })
                            .then((response) => {
                                callback(false, response.data);
                            })
                            .catch((error) => {
                                console.error('Authorization error:', error);
                                callback(true, error);
                            });
                    },
                };
            },
        };

        pusher = new Pusher(data.key, params);
        // pusher.logToConsole = true;
    };

    const subscribeToChatChannel = (user1: number, user2: number) => {
        const minId = Math.min(user1, user2);

        const maxId = Math.max(user1, user2);
        channelName = `private-chat-channel-${minId}-${maxId}`;

        const channel = pusher.subscribe(channelName);

        channel.bind('new-message', (payload: any) => {
            console.log('Id', payload);

            const message = payload.message;

            updateContactsWithNewMessage(message);

            if (message.sender_id === data.user.user_id) {
                return;
            }

            if (
                (message.sender_id == activeConversationUserID && message.receiver_id == data.user.user_id) ||
                (message.receiver_id == activeConversationUserID && message.sender_id == data.user.user_id)
            ) {
                conversation = [...conversation, message];

                scrollToBottom();
            }

            if (typeof unseenMessagesCount === 'number') {
                unseenMessagesCount += 1;
            }
        });

        channel.bind('deleted-message', (payload: any) => {
            const { messageId } = payload;
            conversation = conversation.filter((msg) => msg.id !== messageId);
        });

        channel.bind('updated-message', (payload: any) => {
            const updatedMessage = payload.message;
            conversation = conversation.map((msg) =>
                msg.id === updatedMessage.id ? { ...msg, price: updatedMessage.price } : msg
            );
        });

        channel.bind('user-typing', (payload: any) => {
            if (payload.sender_id === data.user.user_id) {
                return;
            }
            isReceiverTyping = true;
        });

        channel.bind('user-stopped-typing', (payload: any) => {
            if (payload.sender_id === data.user.user_id) {
                return;
            }

            isReceiverTyping = false;
        });
    };

    onDestroy(() => {
        if (pusher) {
            try {
                pusher.disconnect();
            } catch (error) {
                console.error('Error during cleanup:', error);
            }
        }
    });

    const togglePicker = (): void => {
        showPicker = !showPicker;
        if (showPicker && !picker) {
            initializePicker();
        } else if (picker) {
            picker = null;
            if (pickerContainer && pickerContainer.childNodes) {
                pickerContainer.innerHTML = '';
            }
        }
    };

    const initializePicker = (): void => {
        picker = new Picker({
            onEmojiSelect: (emoji: any) => {
                newMessage += emoji.native;
                showPicker = false;
                picker = null;
                if (pickerContainer && pickerContainer.childNodes) {
                    pickerContainer.innerHTML = '';
                }
            },
            data: async () => {
                const response = await fetch('https://cdn.jsdelivr.net/npm/@emoji-mart/data');
                return response.json();
            },
            theme: document.documentElement.classList.contains('dark') ? 'dark' : 'light',
        });

        pickerContainer.appendChild(picker);
    };

    const fetchConversation = (contactId: any): void => {
        ChatLoader = true;

        axios
            .get(`/my/messenger/fetchMessages/${contactId}`, { responseType: 'json' })
            .then((response) => {
                const result = response.data;

                if (result.status === 'success') {
                    conversation = result.data.messages;
                    activeConversationUserID = contactId;
                    subscribeToChatChannel(data.user.user_id, contactId);
                    reloadConversationHeader();
                    scrollToBottom('instant');
                    initMarkAsSeen();
                }
            })
            .catch((error) => console.error('Error fetching messages:', error))
            .finally(() => (ChatLoader = false));
    };

    const reloadConversationHeader = (): void => {
        if (conversation[0]) {
            const contact = conversation[0];
            const isReceiver = contact.receiver_id !== activeConversationUserID;
            activeConversationUserUserName = isReceiver ? contact.sender.username : contact.receiver.username;
            activeConversationUserAvatar = isReceiver ? contact.sender.avatar : contact.receiver.avatar;
            activeConversationUserName = isReceiver ? `${contact.sender.name} ` : `${contact.receiver.name}`;
            activeConversationUserProfileUrl = isReceiver ? contact.sender.profileUrl : contact.receiver.profileUrl;
        }
    };

    const clearMessagePrice = (): void => {
        isPriceUpdateMode = false;
        isPaidMessage = false;
        messagePrice = 5;
    };

    const sendMessage = async (): Promise<void> => {
        if (!newMessage.trim() && uploadedFiles.length === 0) return;

        messageSending = true;

        const tempMessage = {
            id: `temp-${uuidv4()}`,
            message: newMessage,
            attachments: uploadedFiles,
            sender_id: data.user.user_id,
            receiver_id: !newChat ? activeConversationUserID : newChatUserId,
            status: 'sending',
            price: isPaidMessage ? messagePrice : 0,
        };

        conversation = [...conversation, tempMessage];
        scrollToBottom();

        try {
            const response = await axios.post('/my/messenger/sendMessage', {
                message: newMessage,
                attachments: uploadedFiles,
                receiverIDs: !newChat ? [activeConversationUserID] : [newChatUserId],
                price: isPaidMessage ? messagePrice : 0,
            });

            if (myDropzone) {
                myDropzone.removeAllFiles(true);
            }

            uploadedFiles = [];

            newMessage = '';

            conversation = conversation.map((msg) => (msg.id === tempMessage.id ? response.data.data.message : msg));

            clearMessagePrice();

            console.log('Send message', response.data.data.message);

            updateContactsWithNewMessage(response.data.data.message);

            if (newChat) {
                newChat = false;
                fetchContacts();
                fetchConversation(newChatUserId);
            }
        } catch (error) {
            console.error('Error sending message:', error);
            conversation = conversation.map((msg) => (msg.id === tempMessage.id ? { ...msg, status: 'failed' } : msg));
        } finally {
            messageSending = false;
        }
    };

    const showMessageDeleteDialog = (messageID: string): void => {
        deleteDialogOpen = true;
        deleteMsgID = messageID;
    };

    const deleteMessage = (): void => {
        axios
            .delete(`/my/messenger/delete/${deleteMsgID}`, { responseType: 'json' })
            .then((response) => {
                if (response.data.status === 'success') {
                    conversation = conversation.filter((msg) => msg.id !== deleteMsgID);
                }
            })
            .catch((error) => console.error('Error deleting message:', error))
            .finally(() => (deleteDialogOpen = false));
    };

    const initMarkAsSeen = (): void => {
        axios
            .post(`/my/messenger/markSeen`, { userID: activeConversationUserID })
            .then((response) => {
                if (response.data.status === 'success') {
                    updateNotificationBadge(response.data.data?.count ?? 0);
                }
            })
            .catch((error) => console.error('Error marking messages as seen:', error));
    };

    const updateNotificationBadge = (count: number): void => {
        const element = document.querySelector('.menu-notification-badge.chat-menu-count');
        if (element instanceof HTMLElement) {
            const currentCount = parseInt(element.innerHTML) || 0;
            const newCount = currentCount - count;

            if (newCount > 0) {
                element.classList.remove('d-none');
                element.innerHTML = newCount.toString();
            } else {
                element.classList.add('d-none');
            }
        }
    };

    const handleKeydown = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            if (newMessage.trim() || uploadedFiles.length > 0) {
                sendMessage();
            }
        }
    };

    const updateContactsWithNewMessage = (message: any) => {
        const contactId = message.sender_id === data.user.user_id ? message.receiver_id : message.sender_id;
        contacts = contacts.map((contact) => {
            if (contact.contact_id === contactId) {
                return {
                    ...contact,
                    last_message: message.message,
                    last_message_sender_id: message.sender_id,
                    isSeen: message.sender_id === data.user.user_id ? contact.isSeen : 0,
                    created_at: 'just now',
                };
            }
            return contact;
        });
    };

    const clickedOnSingleImage = async (attachment: any) => {
        openSingleAttachment = true;
        const decryptedData = await processImage(attachment.key, attachment.path);
        try {
            const result = await renderImageToCanvas(decryptedData);
        } catch (error) {
            console.error('Failed to render image:', error);
        }
    };

    async function processImage(key: any, path: any) {
        try {
            const response = await fetch(path);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const decryptedData = await decryptBase64(data.ct, data.iv, key);

            return decryptedData;
        } catch (error: any) {
            console.error('Error:', error);
            if (typeof launchToast === 'function') {
                toast.error('Error loading Media', error);
            }
            throw error;
        }
    }

    const renderImageToCanvas = async (arrayBuffer: ArrayBuffer, containerId = 'imageRenderSection') => {
        // Create canvas element
        const canvas = document.createElement('canvas');
        const canvasId = `canvas_${Date.now()}`;
        canvas.id = canvasId;
        canvas.className = 'w-100';

        const container = document.getElementById(containerId);
        container?.appendChild(canvas);

        // Create image from array buffer
        const blob = new Blob([arrayBuffer], { type: 'image/avif' });
        const imageUrl = URL.createObjectURL(blob);

        // Create image element to load the data
        const img = new Image();

        return new Promise((resolve, reject) => {
            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;

                // Draw image to canvas
                const ctx = canvas.getContext('2d');
                ctx?.drawImage(img, 0, 0);

                // Create div with background if needed
                const divBackground = document.createElement('div');
                divBackground.id = `divBackground_${canvasId.split('_')[1]}`;
                divBackground.style.backgroundImage = `url(${canvas.toDataURL()})`;
                divBackground.style.backgroundSize = 'cover';
                divBackground.style.backgroundPosition = 'center';
                divBackground.style.width = '100%';
                divBackground.style.height = '300px'; // You can adjust this height as needed

                // Add the background div after the canvas
                container?.appendChild(divBackground);
                canvas.style.display = 'none';

                // Cleanup
                URL.revokeObjectURL(imageUrl);

                resolve({
                    canvas: canvas,
                    divBackground: divBackground,
                    dataUrl: canvas.toDataURL(),
                });
            };

            img.onerror = reject;
            img.src = imageUrl;
        });
    };

    const updateMessagePrice = async () => {
        const response = await axios.post('/my/messenger/updateMessagePrice', {
            messageId: priceEditMessageID,
            price: messagePrice,
        });

        if (response && response.status === 200) {
            console.log('Response Data', response.data);

            conversation = conversation.map((msg) =>
                msg?.id === priceEditMessageID ? { ...msg, price: response.data.data.price } : msg
            );

            console.log('Updated Price');
        }
    };

    const clickedOnMultipleImage = () => {
        console.log('clicked');
    };
</script>

<svelte:head>
    <link href="https://unpkg.com/dropzone@6.0.0-beta.2/dist/dropzone.css" rel="stylesheet" type="text/css" />
</svelte:head>

<div class="flex">
    <div
        class="min-h-[92vh] border-r-[1px] border-[#dee2e6] md:min-h-screen md:w-1/4"
        class:w-full="{isMobile && !mobileShowChat}"
        class:w-0="{isMobile && mobileShowChat}"
        class:overflow-x-hidden="{isMobile && mobileShowChat}"
        class:overflow-y-hidden="{isMobile}"
    >
        <div class="conversations-wrapper-header flex items-center justify-between pb-2 pr-1 pt-3">
            <h5 class="text-bold m-0 truncate pl-3">Contacts</h5>
            <Button
                variant="ghost"
                class="px-2 py-1 hover:bg-transparent focus:bg-transparent"
                on:click="{() => {
                    newChat = !newChat;
                    mobileShowChat = !mobileShowChat;
                    !data.messengerVars.lastContactID ? (ChatLoader = !newChat) : '';
                }}"
            >
                <Icon src="{PencilSquare}" size="{'24px'}" />
            </Button>
        </div>

        <div class="conversations-list">
            {#if contacts && contacts.length > 0}
                {#each contacts as contact}
                    <button
                        class="contact-box flex w-full p-2 text-left contact-{contact.contact_id} cursor-pointer gap-[10px]"
                        on:click="{() => {
                            fetchConversation(String(contact.contact_id));
                            mobileShowChat = true;
                            newChat = false;
                            data.messengerVars.lastContactID = true;
                        }}"
                        class:bg-[#e9ecef]="{!isMobile &&
                            contact.contact_id == parseInt(activeConversationUserID) &&
                            !newChat}"
                    >
                        <img
                            src="{contact.receiver_id === data.user.user_id
                                ? contact.sender_avatar
                                : contact.receiver_avatar}"
                            class="contact-avatar h-[50px] w-[50px] rounded-[100%]"
                            alt="avatar"
                        />
                        <div class="m-0 flex flex-1 flex-col justify-center truncate">
                            <div
                                class="contact-name m-0 truncate text-black/65 {contact.last_message_sender_id !==
                                    data.user.user_id && contact.isSeen === 0
                                    ? 'font-bold'
                                    : ''}"
                            >
                                {contact.receiver_id === data.user.user_id
                                    ? contact.sender_name
                                    : contact.receiver_name}
                            </div>
                            <small class="message-excerpt-holder flex truncate">
                                <!-- <span
                                    class="mr-1 text-[#6c757d]

                                    ${contact.last_message_sender_id !== data.user.user_id ? 'hidden' : ''}"
                                >
                                    {contact.last_message_sender_id !== data.user.user_id
                                        ? `${contact.sender_name}`
                                        : 'You:'}
                                </span> -->
                                <div
                                    class="contact-message m-0 flex-1 truncate text-[#6c757d] ${contact.last_message_sender_id !==
                                        data.user.user_id && contact.isSeen === 0
                                        ? 'font-bold'
                                        : ''}"
                                >
                                    {contact.last_message}
                                </div>
                                <div class="flex dark:text-black/65">
                                    <div class="ml-1 font-bold dark:text-black/65">
                                        {contact.created_at !== null ? '∙' : ''}
                                    </div>
                                    {#if contact.created_at !== null}
                                        &nbsp; {contact.created_at}
                                    {/if}
                                </div>
                            </small>
                        </div>
                    </button>
                {/each}
            {:else if data.messengerVars.lastContactID == false}
                <div class="mb-3 mt-3 flex pl-3">
                    <span>Click the text bubble to send a new message.</span>
                </div>
            {:else}
                {#each Array(3) as _, i}
                    <div class="mx-3 mb-2 flex items-center space-x-4 border-[1px] px-2 py-3">
                        <Skeleton class="h-12 w-12 rounded-full" />
                        <div class="w-[80%] space-y-2">
                            <Skeleton class="h-4 w-[100%]" />
                            <Skeleton class="h-4 w-[100%]" />
                        </div>
                    </div>
                {/each}
            {/if}
        </div>
    </div>
    <div
        class="border md:w-3/4"
        class:w-full="{isMobile && mobileShowChat}"
        class:w-0="{isMobile && !mobileShowChat}"
        class:overflow-x-hidden="{isMobile && !mobileShowChat}"
    >
        {#if ChatLoader}
            <svelte:component this="{ConversationHeaderBox}" {data} />
            <svelte:component this="{ConversationBox}" {data} bind:newChat />
        {:else}
            {#if newChat}
                <svelte:component
                    this="{NewConversationHeader}"
                    bind:value="{newChat}"
                    bind:ChatLoader
                    bind:newChatUser="{newChatUserId}"
                    bind:mobileShowChat
                    {data}
                />
            {:else}
                <svelte:component
                    this="{ConversationHeader}"
                    activeUser="{[
                        activeConversationUserID,
                        activeConversationUserUserName,
                        activeConversationUserAvatar,
                        activeConversationUserName,
                        activeConversationUserProfileUrl,
                    ]}"
                    bind:mobileShowChat
                />
            {/if}

            <div
                class="conversation-content scrollbar-hide max-h-[73vh] min-h-[73vh] flex-1 overflow-auto px-3 pb-1 pt-4 md:max-h-[82vh] md:min-h-[82vh]"
                style="scrollbar-width: none; -ms-overflow-style: none;"
                bind:this="{chatContainer}"
            >
                {#if conversation.length !== 0 && !newChat}
                    {#each conversation as message}
                        {#if message?.hasUserUnlockedMessage === false && message.price > 0 && message.sender_id !== data.user.user_id}
                            <!-- Locked (Paid) Message -->
                            <div
                                class="no-gutters message-box flex w-full px-0 pb-1 pt-1 {message.sender_id ===
                                data.user.user_id
                                    ? 'sender flex flex-row-reverse pr-1'
                                    : 'pl-0'}"
                                data-messageid="{message.id}"
                                id="m-{message.id}"
                            >
                                <div
                                    class="paid-message-box message-box m-0 rounded-[18px] px-[12px] py-[9px] {message.sender_id ===
                                    data.user.user_id
                                        ? 'rounded-tr-none bg-gradient-to-r from-bmn-400 to-bmn-500 text-white'
                                        : 'rounded-tl-none bg-[#e9ecef] text-[#252f40]'} w-[400px]"
                                >
                                    <div
                                        class="d-flex mb-2 w-full {message.sender_id === data.user.user_id
                                            ? 'sender d-flex flex-row-reverse pr-1'
                                            : 'pl-0'}"
                                        crypto
                                    >
                                        {message.message === null ? '' : message.message}
                                    </div>
                                    <div>
                                        {#each message?.attachments as attachment, index}
                                            {#if index === 0}
                                                <div class="lockedPreviewWrapper">
                                                    <img
                                                        class="card-img !rounded-lg"
                                                        alt="card-img"
                                                        src="{attachment?.blurred_version?.startsWith('//')
                                                            ? attachment?.blurred_version?.substring(1)
                                                            : attachment?.blurred_version}"
                                                    />
                                                </div>
                                            {/if}
                                        {/each}

                                        <div class="flex-column-reverse mt-2 flex">
                                            <Button class=" rounded-[100px]">
                                                Locked message Unlock for {data.currencySymbol}
                                                {message.price}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {:else if message?.message != null}
                            <!-- Text + Optional Attachments -->
                            <div
                                class="no-gutters message-box w-full px-0 pb-1 pt-1"
                                data-messageid="{message.id}"
                                id="m-{message.id}"
                            >
                                <div class="flex">
                                    <!-- svelte-ignore a11y-no-static-element-interactions -->
                                    <!-- svelte-ignore a11y-mouse-events-have-key-events -->
                                    <div
                                        class="flex w-full {message.sender_id === data.user.user_id
                                            ? 'sender flex flex-row-reverse pr-1'
                                            : 'pl-0'}"
                                        on:mouseover="{() => (hoveredMessageId = message.id)}"
                                        on:mouseout="{() => (hoveredMessageId = '')}"
                                    >
                                        <div>
                                            <div
                                                class="message-bubble text-break m-0 rounded-[18px] px-[12px] py-[9px] {message.sender_id ===
                                                data.user.user_id
                                                    ? 'rounded-tr-none bg-gradient-to-r from-bmn-400 to-bmn-500 text-white'
                                                    : ' rounded-tl-none bg-[#e9ecef] text-[#252f40]'}"
                                            >
                                                {message.message}
                                            </div>

                                            {#if message.status === 'sending'}
                                                <small class="text-right text-gray-500">Sending...</small>
                                            {:else if message.status === 'failed'}
                                                <small class="text-red-500">Failed to send</small>
                                            {/if}
                                        </div>

                                        <MessageAction
                                            {message}
                                            {hoveredMessageId}
                                            userId="{data.user.user_id}"
                                            onDeleteMessage="{showMessageDeleteDialog}"
                                            openPriceModal="{(msg) => {
                                                const { id, price } = msg;
                                                console.log('Id', id);
                                                isPriceUpdateMode = true;
                                                messagePrice = price;
                                                priceEditMessageID = id;
                                                openPriceModal = true;
                                            }}"
                                        />
                                    </div>
                                </div>

                                <!-- svelte-ignore a11y-no-static-element-interactions -->
                                {#if message.attachments.length > 1}
                                    <!-- svelte-ignore a11y-no-static-element-interactions -->
                                    <!-- svelte-ignore a11y-mouse-events-have-key-events -->
                                    <div
                                        class="flex w-full {message.sender_id === data.user.user_id
                                            ? 'sender flex-row-reverse pr-1'
                                            : 'pl-0'}"
                                        on:mouseover="{() => (hoveredMessageId = message.id)}"
                                        on:mouseout="{() => (hoveredMessageId = '')}"
                                    >
                                        <div class="attachments-holder row no-gutters flex-row-reverse">
                                            {#each message.attachments as attachment}
                                                {#if isVideo.includes(attachment.type)}
                                                    <!--  the attachment type is video -->
                                                    <a href="{attachment.path}" rel="mswp" title="" class="mr-2 mt-2">
                                                        <div class="video-wrapper">
                                                            <video
                                                                class="video-preview"
                                                                src="{attachment.path}"
                                                                width="150"
                                                                height="150"
                                                                controls
                                                                autoplay
                                                                muted
                                                            ></video>
                                                        </div>
                                                    </a>
                                                {:else if isAudio.includes(attachment.type)}
                                                    <!-- Audio attachment type -->
                                                    <a
                                                        href="{attachment.path}"
                                                        rel="mswp"
                                                        title=""
                                                        class="mr-2 mt-2 flex items-center"
                                                    >
                                                        <div class="video-wrapper">
                                                            <audio
                                                                id="video-preview"
                                                                src="{attachment.path}"
                                                                controls
                                                                muted
                                                            ></audio>
                                                        </div>
                                                    </a>
                                                {:else if isImage.includes(attachment.type)}
                                                    <!-- image attachment type -->

                                                    <button
                                                        on:click="{() => {
                                                            clickedOnMultipleImage();
                                                        }}"
                                                    >
                                                        <!-- multiple attachments -->
                                                    </button>
                                                {:else}
                                                    <img
                                                        src="{attachment.thumbnail}"
                                                        class="mr-2 mt-2"
                                                        alt="file thumbnail"
                                                    />
                                                {/if}
                                            {/each}
                                        </div>
                                        <MessageAction
                                            {message}
                                            {hoveredMessageId}
                                            userId="{data.user.user_id}"
                                            onDeleteMessage="{showMessageDeleteDialog}"
                                            openPriceModal="{(msg) => {
                                                const { id, price } = msg;
                                                isPriceUpdateMode = true;
                                                messagePrice = price;
                                                priceEditMessageID = id;
                                                openPriceModal = true;
                                            }}"
                                        />
                                    </div>
                                {/if}
                            </div>
                        {:else}
                            <!-- Attachments only -->
                            <!-- svelte-ignore a11y-no-static-element-interactions -->
                            <!-- svelte-ignore a11y-mouse-events-have-key-events -->
                            <div
                                class="flex w-full {message.sender_id === data.user.user_id
                                    ? 'sender flex-row-reverse pr-1'
                                    : 'pl-0'}"
                                on:mouseover="{() => (hoveredMessageId = message.id)}"
                                on:mouseout="{() => (hoveredMessageId = '')}"
                            >
                                <div class="attachments-holder row no-gutters flex-row-reverse">
                                    {#each message.attachments as attachment}
                                        {#if isVideo.includes(attachment.type)}
                                            <a href="{attachment.path}" rel="mswp" title="" class="mr-2 mt-2">
                                                <div class="video-wrapper">
                                                    <video
                                                        class="video-preview"
                                                        src="{attachment.path}"
                                                        width="150"
                                                        height="150"
                                                        controls
                                                        autoplay
                                                        muted
                                                    ></video>
                                                </div>
                                            </a>
                                        {:else if isAudio.includes(attachment.type)}
                                            <a
                                                href="{attachment.path}"
                                                rel="mswp"
                                                title=""
                                                class="mr-2 mt-2 flex items-center"
                                            >
                                                <div class="video-wrapper">
                                                    <audio id="video-preview" src="{attachment.path}" controls muted
                                                    ></audio>
                                                </div>
                                            </a>
                                        {:else if isImage.includes(attachment.type)}
                                            <button
                                                on:click="{() => {
                                                    clickedOnSingleImage(attachment);
                                                }}"
                                            >
                                                <img
                                                    src="{attachment.thumbnail}"
                                                    alt="thumbnail"
                                                    class="mr-2 mt-2 h-[120px] w-[120px] rounded-lg"
                                                />
                                            </button>
                                        {:else}
                                            <img
                                                src="{attachment.thumbnail}"
                                                alt="thumbnail"
                                                class="mr-2 mt-2 h-[120px] w-[120px] rounded-lg"
                                            />
                                        {/if}
                                    {/each}
                                </div>
                                <MessageAction
                                    {message}
                                    {hoveredMessageId}
                                    userId="{data.user.user_id}"
                                    onDeleteMessage="{showMessageDeleteDialog}"
                                    openPriceModal="{(msg) => {
                                        const { id, price } = msg;
                                        isPriceUpdateMode = true;
                                        messagePrice = price;
                                        priceEditMessageID = id;
                                        openPriceModal = true;
                                    }}"
                                />
                            </div>
                        {/if}
                    {/each}
                    {#if isReceiverTyping}
                        <div class="typing-indicator mt-2">
                            <SendingAnimation />
                        </div>
                    {/if}
                {/if}
            </div>
        {/if}

        <div
            bind:this="{previewsContainer}"
            class="dropzone-previews dropzone dz-preview !border-2 border-solid !border-bmn-500"
            class:hidden="{!dropzoneLoading && uploadedFiles.length === 0}"
        ></div>
        <div
            class="conversation-writeup mb-1 flex items-center pb-1 pt-1"
            class:pointer-events-none="{conversation.length == 0 && !newChat}"
            class:opacity-50="{conversation.length == 0 && !newChat}"
        >
            <div>
                <div bind:this="{dropzoneElement}" class="dropzone hidden"></div>
                <div class="file-upload-button text-bmn-500">
                    <Icon src="{Document}" size="{'24px'}" solid />
                </div>
            </div>

            <form class="message-form flex-1">
                <div class="messageBoxInput-wrapper relative flex w-full flex-wrap items-stretch">
                    <Textarea
                        bind:value="{newMessage}"
                        on:click="{() => {
                            initMarkAsSeen();
                        }}"
                        on:keydown="{(event) => {
                            handleKeydown(event);
                            if (!isTyping) {
                                isTyping = true;
                                axios.post('/my/messenger/typing', {
                                    receiverId: !newChat ? activeConversationUserID : newChatUserId,
                                    channelName: channelName,
                                });
                            }
                        }}"
                        on:blur="{() => {
                            axios.post('/my/messenger/stop-typing', {
                                receiverId: !newChat ? activeConversationUserID : newChatUserId,
                                channelName: channelName,
                            });
                            isTyping = false;
                        }}"
                        class="messageBoxInput h-[45px] max-h-[131px] min-h-[45px] w-full rounded-[30px] border border-[#0000004D] px-[0.75rem] py-[0.5rem] leading-[1.7rem] dark:border-2 dark:border-gray-900"
                        placeholder="Write a message.."
                    />
                    <div class="z-index-3 absolute right-[0.5rem] top-[0] flex items-center justify-center">
                        <Button
                            on:click="{togglePicker}"
                            variant="ghost"
                            class="px-2 py-0 text-bmn-500 hover:bg-transparent hover:text-bmn-300 focus:bg-transparent"
                        >
                            😊
                        </Button>
                        <span id="emoji-container" class="absolute bottom-10 right-0" bind:this="{pickerContainer}"
                        ></span>
                    </div>
                </div>
            </form>
            <div class="flex">
                {#if data.creatorCanEarnMoney}
                    <Button
                        variant="ghost"
                        class="px-2 py-1 text-bmn-500 hover:bg-transparent hover:text-bmn-300 focus:bg-transparent"
                        on:click="{() => {
                            openPriceModal = true;
                        }}"
                    >
                        <Icon src="{isPaidMessage ? LockClosed : LockOpen}" size="{'24px'}" solid />
                    </Button>
                {/if}
                <Button
                    variant="ghost"
                    class="px-2 py-1 text-bmn-500 hover:bg-transparent hover:text-bmn-300 focus:bg-transparent"
                    on:click="{sendMessage}"
                >
                    {#if messageSending}
                        <LoaderCircle class="mr-2 h-4 w-4 animate-spin" />
                    {:else}
                        <Icon src="{PaperAirplane}" size="{'24px'}" solid />
                    {/if}
                </Button>
            </div>
        </div>
    </div>
</div>

<Dialog.Root bind:open="{deleteDialogOpen}">
    <Dialog.Content class="sm:max-w-[425px]">
        <Dialog.Header>
            <Dialog.Title>Delete message</Dialog.Title>
            <Dialog.Description>Are you sure you want to delete this message?</Dialog.Description>
        </Dialog.Header>
        <Dialog.Footer class="md:justify-center">
            <Dialog.Close>
                <Button on:click="{deleteMessage}">Delete</Button>
            </Dialog.Close>
        </Dialog.Footer>
    </Dialog.Content>
</Dialog.Root>

<Dialog.Root bind:open="{openSingleAttachment}">
    <Dialog.Content class="lg">
        <div id="imageRenderSection" class="w-100"></div>

        <Dialog.Footer class="md:justify-center">
            <Dialog.Close>
                <Button>Close</Button>
            </Dialog.Close>
        </Dialog.Footer>
    </Dialog.Content>
</Dialog.Root>

<Dialog.Root bind:open="{openPriceModal}">
    <Dialog.Content class="sm:max-w-[425px]">
        <Dialog.Header>
            <Dialog.Title>Set message price</Dialog.Title>
            <Dialog.Description>Only Media Assets are locked, text is visible.</Dialog.Description>
        </Dialog.Header>
        <div class="grid gap-4 py-4">
            <Input id="messagePrice" bind:value="{messagePrice}" type="number" />
        </div>
        <Dialog.Footer>
            <Dialog.Close>
                <Button on:click="{clearMessagePrice}" variant="outline">CLEAR</Button>
            </Dialog.Close>
            <Dialog.Close>
                <Button
                    on:click="{() => {
                        if (isPriceUpdateMode) {
                            updateMessagePrice();
                        } else {
                            isPaidMessage = true;
                        }
                    }}">{isPriceUpdateMode ? 'Update' : 'Save'}</Button
                >
            </Dialog.Close>
        </Dialog.Footer>
    </Dialog.Content>
</Dialog.Root>
